import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import App from "../../components/App";
import { API_URL_V1, windowGlobal } from "../../config";
import GlobalContext from "../../context/GlobalContext";
import { PricingData } from "../../sections/home3/Pricing";
import stripeLogo from "../../assets/image/stripe-logo.svg";
import { Spinner } from "react-bootstrap";

const Billing = () => {
  const [plan, setPlan] = useState(null);
  const [paymethod, setPaymethod] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [upgrading, setUpgrading] = useState(null);
  const [payingInvoice, setPayingInvoice] = useState(null);
  const [refreshAttempt, setRefreshAttempt] = useState(0);
  const { session } = useContext(GlobalContext);
  useEffect(() => {
    if (!session) return;

    axios
      .get(`${API_URL_V1}/billing/plan`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => setPlan(res.data));

    axios
      .get(`${API_URL_V1}/billing/methods`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => setPaymethod(res.data?.[0]));

    axios
      .get(`${API_URL_V1}/billing/invoices`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => setInvoices(res.data));
  }, [session, refreshAttempt]);

  const upgrade = async (planId) => {
    if (!session) return;
    setUpgrading(planId);
    try {
      const result = await axios.post(
        `${API_URL_V1}/billing/upgrade`,
        {
          planId,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.token}`,
          },
        }
      );

      const url = result.data?.trim();
      if (url) {
        windowGlobal?.location?.assign(url);
      } else {
        setRefreshAttempt((prev) => prev + 1);
      }
    } catch (error) {
    } finally {
      setUpgrading(null);
    }
  };

  const update = async () => {
    if (!session) return;
    try {
      const result = await axios.post(`${API_URL_V1}/billing/update`, null, {
        headers: {
          Authorization: `Bearer ${session?.token}`,
        },
      });

      const url = result.data?.trim();
      if (url) {
        windowGlobal?.location?.assign(url);
      }
    } catch (error) {}
  };

  const payInvoice = async (id) => {
    if (!session) return;
    setPayingInvoice(id);
    try {
      const result = await axios.post(
        `${API_URL_V1}/billing/invoices/pay`,
        {
          invoiceId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.token}`,
          },
        }
      );

      setRefreshAttempt((prev) => prev + 1);
    } catch (error) {
    } finally {
      setPayingInvoice(null);
    }
  };

  const contactSales = () => {
    document.getElementById("SonetelWidget").firstChild.click()
  }

  if (!session) return null;

  return (
    <App>
      <h4>Billing</h4>
      {plan && (
        <>
          <div>Your current plan: {plan.name}</div>
        </>
      )}
      {paymethod && (
        <div className="mt-10">
          <h6>Payment Methods</h6>
          <div className="mt-6 d-flex flex-row card py-2 px-4 w-50">
            <span className="mr-4 flex-grow-1">xxxx-{paymethod.last4}</span>
            <span className="text-capitalize flex-grow-1">
              {paymethod.brand}
            </span>
            <a href="#" onClick={update}>
              Update
            </a>
          </div>
        </div>
      )}

      {invoices &&
        invoices.find(
          (iv) => iv.status !== "paid" && iv.status !== "draft"
        ) && (
          <div className="alert alert-danger mt-10">
            We failed to charge you for recent invoice(s). Please update your
            payment method and retry payment to continue using the service
            uninterrupted.
          </div>
        )}

      {invoices && (
        <div className="mt-10">
          <h6>Invoices</h6>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Period</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((iv) => (
                <tr>
                  <td>
                    {new Date(iv.periodStart * 1000).toLocaleDateString()} -{" "}
                    {new Date(iv.periodEnd * 1000).toLocaleDateString()}
                  </td>
                  <td>${(iv.amountDue / 100).toFixed(2)}</td>
                  <td>
                    {iv.status === "paid" ? (
                      <span class="badge badge-success">Paid</span>
                    ) : iv.status === "draft" ? (
                      <span class="badge badge-light">Upcoming</span>
                    ) : (
                      <span class="badge badge-danger">{iv.status}</span>
                    )}
                  </td>
                  <td>
                    {iv.pdfLink && (
                      <a href={iv.pdfLink} target="_blank" title="Download PDF">
                        <FaDownload />
                      </a>
                    )}
                    {iv.status !== "draft" && iv.status !== "paid" && (
                      <a
                        href="#"
                        onClick={() => payInvoice(iv.id)}
                        disabled={iv.id === payingInvoice}
                        className="ml-4"
                      >
                        {iv.id === payingInvoice ? (
                          <Spinner className="spinner-border spinner-border-sm" />
                        ) : (
                          "Retry"
                        )}
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-10">
        <h6>Upgrade Options</h6>
        <div className="mt-6">
          {PricingData.map((pd, i) => (
            <div className="card mt-4">
              <div className="card-body text-center">
                <h3>{pd.title}</h3>
                {pd.price && <div>
                  Starts from{" "}
                  {pd.reducedPrice ? (
                    <>
                      <s className="mr-2">${pd.price}</s>
                      <span>${pd.reducedPrice}/month</span>
                    </>
                  ) : (
                    <s>${pd.price}/month</s>
                  )}
                </div>}
                {pd.price ? (
                  <button
                    className="mt-4 btn btn-2 btn-outline-primary rounded-8"
                    onClick={
                      pd.planId === plan?.id || upgrading === pd.planId
                        ? null
                        : () => upgrade(pd.planId)
                    }
                    disabled={pd.planId === plan?.id || upgrading === pd.planId}
                  >
                    {upgrading === pd.planId ? (
                      <Spinner className="spinner-border spinner-border-sm" />
                    ) : pd.planId === plan?.id ? (
                      "Current Plan"
                    ) : PricingData.findIndex((pd) => pd.planId == plan?.id) >
                      i ? (
                      "Downgrade"
                    ) : (
                      "Upgrade"
                    )}
                  </button>
                ) : (
                  <button onClick={contactSales} className="mt-4 btn btn-2 btn-outline-primary rounded-8">
                    Contact Sales
                  </button>
                )}
                <div className="mt-6">
                  {pd.list.map((li) => (
                    <div>{li}</div>
                  ))}
                </div>
              </div>
              <div className="card-footer text-center">
                <div>
                  <span style={{ fontSize: "0.7rem" }}>secured by</span>
                  <img src={stripeLogo} height={20} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-2" style={{ fontSize: "0.8rem" }}>
          * If an event exceeds the character limit, it will be counted as more
          than 1 event
        </div>
      </div>
    </App>
  );
};

export default Billing;
